<template>
  <div class="page-box">
    <div class="fl-jcsb-aic mb-10">
      <el-radio-group
        v-model="readStatus"
        size="medium"
        class="mb20"
        @change="handleLabel"
      >
        <el-radio-button label="隐患事件">隐患事件</el-radio-button>
      </el-radio-group>
      <el-button type="info" size="medium" @click="lastPage">关闭</el-button>
    </div>
    <div class="search-box">
      <el-form :inline="true" :model="searchForm" size="medium">
        <el-form-item label="" class="">
          <el-input
            v-model="input2"
            class="te"
            placeholder="关键字搜索"
            @change="handleQuery"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-date-picker
            placeholder="发布时间"
            v-model="searchForm.time"
            type="datetime"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="medium"
        class="search-right-btn"
        @click="isShowDialog = true"
        >新增</el-button
      >
    </div>
    <div class="table-box">
      <el-table
        :data="tableData"
        border
        size="medium"
        height="calc(100vh - 10.7rem)"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="序号"
          type="index"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          label="隐患类型"
          prop="typeCodeCn"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="位置"
          prop="position"
          width="300"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="描述"
          prop="description"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="区划码"
          prop="regionCode"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="warning" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination_box">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :current-page="paramData.pageNo"
          :page-size="paramData.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="formData.id ? '编辑' : '新增'"
      :visible="isShowDialog"
      :destroy-on-close="true"
      @close="btnCancel"
    >
      <!-- 匿名插槽 -->
      <el-form
        ref="addForm"
        label-width="120px"
        :model="formData"
        :rules="rules"
      >
        <el-form-item label="隐患位置" prop="position">
          <el-input
            v-model="formData.position"
            style="width: 80%"
            placeholder="隐患位置"
          />
        </el-form-item>
        <el-form-item label="区划码" prop="regionCode">
          <el-input
            v-model="formData.regionCode"
            style="width: 80%"
            placeholder="区划码"
          />
        </el-form-item>
        <el-form-item label="隐患类型" prop="typeCode">
          <el-select
            v-model="formData.typeCode"
            style="width: 80%"
            placeholder="请选择"
            @blur="checkManager"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.value"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="网格">
          <el-input
            v-model="formData.gridName"
            style="width: 80%"
            placeholder="网格"
          />
        </el-form-item>
        <el-form-item label="具体描述">
          <el-input
            v-model="formData.description"
            style="width: 80%"
            placeholder="隐患具体描述"
            type="textarea"
            :rows="3"
          />
        </el-form-item>
      </el-form>
      <!-- el-dialog有专门放置底部操作栏的 插槽  具名插槽 -->
      <el-row slot="footer" type="flex" justify="center">
        <!-- 列被分为24 -->
        <el-col :span="6">
          <el-button type="primary" size="small" @click="btnOK">确定</el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      isShowDialog: false,
      readStatus: "隐患事件",
      searchForm: {
        SearchValue: "",
      },
      input2: "",
      tableData: [],
      total: 0,
      paramData: {
        SearchValue: "",
        PageNo: 1,
        PageSize: 10,
        TypeCode: "",
      },
      // 定义表单数据
      formData: {
        typeCode: "",
        position: "",
        description: "",
        regionCode: "",
        gridName: "",
      },
      // 定义校验规则
      rules: {
        position: [
          { required: true, message: "隐患位置不能为空", trigger: "blur" },
        ],
        regionCode: [
          { required: true, message: "区划码不能为空", trigger: "blur" },
        ],
        typeCode: [
          { required: true, message: "隐患类型不能为空", trigger: "blur" },
        ],
      },
      options: [],
    };
  },
  watch: {},
  mounted() {
    this.getHiddangerPage(this.paramData);
    this.getInformation();
  },
  methods: {
    getHiddangerPage(params) {
      this.api.apiHiddangerPage(params).then((res) => {
        this.tableData = res.data.rows;
        this.total = res.data.totalRows;
      });
    },
    async getInformation() {
      const { data } = await this.api.apiSysDictTypeDropDown({
        Code: "hiddanger_type",
      });
      this.options = this.options.concat(data);
    },
    handleLabel() {
      if (this.readStatus == "模块1") {
        console.log(this.readStatus);
      }
    },
    // 上一页
    lastPage() {
      this.$router.go(-1);
    },

    //编辑按钮
    handleEdit(val) {
      (this.formData.typeCode = val.typeCode),
        (this.formData.position = val.position),
        (this.formData.description = val.description),
        (this.formData.regionCode = val.regionCode),
        (this.formData.gridName = val.gridName);
      this.formData.id = val.id;
      this.isShowDialog = true;
    },
    async handleDelete(val) {
      console.log(val);
      await this.$confirm("确定要删除吗");
      // 发送请求
      await this.api.apiHiddangerDelete({ id: val.id });
      this.$message.success("删除成功");
      this.getHiddangerPage(this.paramData);
    },
    //查询
    handleQuery() {
      this.paramData.SearchValue = this.input2;
      const { data } = this.getHiddangerPage(this.paramData);
      this.tableData = data.rows;
    },
    // 分页操作
    handleSizeChange(val) {
      // this.paramData.PageNo = val;
      // this.getHiddangerPage(this.paramData);
    },
    handleCurrentChange(val) {
      this.paramData.PageNo = val;
      this.getHiddangerPage(this.paramData);
    },

    checkManager() {
      setTimeout(() => {
        this.$refs.addForm.validateField("typeCode");
      }, 100);
    },

    //编辑确定按钮
    async btnOK() {
      await this.$refs.addForm.validate();
      if (this.formData.id) {
        await this.api.apiHiddangerEdit(this.formData);
      } else {
        await this.api.apiHiddangerAdd(this.formData);
      }
      this.$message.success("操作成功");
      this.isShowDialog = false;
      this.getHiddangerPage(this.paramData);
    },
    //编辑取消按钮
    btnCancel() {
      this.formData = {
        typeCode: "",
        position: "",
        description: "",
        regionCode: "",
        gridName: "",
      };
      this.isShowDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  height: 100%;
  // overflow: hidden;
  padding: 19px;
  box-sizing: border-box;
  background: #eef3f6;
}
// .search-box {
//   display: flex;
//   justify-content: space-between;
// }
// .search-right-btn {
//   height: 2.1875rem;
// }
// 表格样式
// .table-box {
//   margin-top: 20px;
// }

// 搜索条样式
.search-box {
  position: relative;
  padding: 15px 15px 0;
  background: #fff;
  border-radius: 10px;
  .search-right-btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
/deep/ .el-input__inner {
  color: black !important;
}
.pagination_box {
  display: flex;
  justify-content: flex-end;
}
</style>
